<template>
  <div class="rounded-lg shadow-lg">
    <formComponent mode="edit" :user="user" />
  </div>
</template>
<script>
import formComponent from "./components/formComponent.vue";
export default {
  components: {
    formComponent,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        status: "",
      },
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$store
        .dispatch("user/getUser", this.$route.params.id)
        .then(({ data }) => {
          this.user = data;
          this.total = data.total;
        });
    },
  },
};
</script>
